import _DatabaseModel, { i18n } from "./_DatabaseModel";
import { toModel } from "../mixins/HelperFunctions";
import { mdiEyeOutline, mdiEyeOffOutline } from "@mdi/js";

class DataSet extends _DatabaseModel {
  static model = "data_set";
  static editDialogComponent = "data-sets-edit-dialog";
  static createNewObject = { object: { definition: {} } };

  static tableHeaders = [
    { textKey: "form.label.name", value: "name" },
    { textKey: "person.person", value: "person.name" },
    { textKey: "data_set.label.target_entity", value: "target_entity" },
  ];

  static show = {
    buttons: ["edit", "open"],
    submenuActions: ["duplicate", "activity", "destroy"],
    reloadingModels: [],
  };

  static filter = {
    parts: {
      fields: ["name", "person_id", "target_entity", "public"],
      extended_fields: ["description", "time_filter"],
      time_filter: ["created_at", "updated_at"],
    },
  };

  static public_options = {
    true: "filter.public_levels.shared",
    false: "filter.public_levels.private",
  };

  static target_entities = [
    "Activity",
    "Asset",
    "AssetCategory",
    "AssetGroup",
    "AssetsIssue",
    "AutomatedAction",
    "Contract",
    "DataSet",
    "Document",
    "ExternalEmail",
    "Field",
    "Issue",
    "Kpi",
    "Person",
    "PersonCategory",
    "PriceItem",
    "PriceItemsWarehouse",
    "PriceList",
    "PrimaryAsset",
    "Report",
    "Requirement",
    "Role",
    "Service",
    "Snippet",
    "Subscription",
    "Threat",
    "Translation",
    "User",
    "Vulnerability",
    "Warehouse",
    "WarehouseTransactionItem",
  ];

  static cardFields(object) {
    return {
      src: {
        cardIcon: object.public ? mdiEyeOutline : mdiEyeOffOutline,
        color: object.public ? "success" : "warning",
      },
      title: object.name,
      subtitle: i18n.t(
        `${toModel(object.target_entity)}.${toModel(object.target_entity)}`
      ),
    };
  }

  static basicInfo(object) {
    return [
      {
        labelKey: "data_set.label.target_entity",
        value: i18n.t(
          `${toModel(object.target_entity)}.${toModel(object.target_entity)}`
        ),
        type: "plain",
      },
      {
        labelKey: "person.person",
        value: object.person,
        type: "link",
        to: "persons",
      },
      {
        labelKey: "data_set.label.public",
        value: object.public,
        type: "boolean",
      },
      {
        labelKey: "form.label.created_at",
        value: object.created_at,
        type: "date_time",
      },
    ];
  }

  static tabs = [
    this.detailTab,
    {
      titleKey: "data_set.label.definition",
      permission: "data_sets_tab_definition",
      component: "JsonDefinitionTab",
      input_data: (context) => context.object,
      parameters_f() {},
      disabled: () => false,
    },
  ];
}

export default DataSet;
