import _DatabaseModel, { i18n } from "./_DatabaseModel";

class Mapper extends _DatabaseModel {
  static model = "mapper";
  static editDialogComponent = "mappers-edit-dialog";

  static tableHeaders = [
    { textKey: "form.label.name", value: "name" },
    {
      textKey: "mapper.label.mapper_type",
      value: "mapper_type",
      translationPrefix: "mapper.mapper_types",
    },
  ];

  static show = {
    buttons: ["edit"],
    submenuActions: ["activity", "destroy"],
    reloadingModels: ["mapper"],
  };

  static mapper_types = [
    "mail_receiver_mapper",
    "pohoda_warehouse_mapper",
    "csv_mapper",
    "csv_generator",
    "json_mapper",
  ];

  static cardFields(object) {
    return {
      title: object.name,
      subtitle: i18n.t(`mapper.mapper_types.${object.mapper_type}`),
    };
  }

  static basicInfo(object) {
    return [
      {
        labelKey: "mapper.label.mapper_type",
        value: i18n.t(`mapper.mapper_types.${object.mapper_type}`),
        type: "plain",
      },
      {
        labelKey: "form.label.updated_at",
        value: object.updated_at,
        type: "date_time",
      },
      {
        labelKey: "form.label.created_at",
        value: object.created_at,
        type: "date_time",
      },
    ];
  }

  static tabs = [
    this.detailTab,
    {
      titleKey: "mapper.label.definition",
      permission: "mappers_tab_definition",
      component: "JsonDefinitionTab",
      input_data: (context) => context.object,
      parameters_f() {},
      disabled: (context) => context.dataShow_disableUpdate,
    },
    {
      titleKey: "connector_run.connector_runs",
      permission: "mappers_tab_run",
      component: "ConnectorRunsTab",
      input_data: (context) => context.object.connector_runs,
      parameters_f() {},
      disabled: () => false,
    },
  ];
}

export default Mapper;
