import _DatabaseModel from "./_DatabaseModel";

class Role extends _DatabaseModel {
  static model = "role";
  static showSize = 8;
  static editDialogComponent = "roles-edit-dialog";

  static createNewObject = {
    object: {
      abilities: {
        assets: {
          see: false,
          create: false,
          update: false,
          destroy: false,
          manage_permissions: false,
        },
        persons: {
          see: false,
          create: false,
          update: false,
          destroy: false,
          manage_permissions: false,
        },
        contracts: {
          see: false,
          create: false,
          update: false,
          destroy: false,
          manage_permissions: false,
        },
        services: {
          see: false,
          create: false,
          update: false,
          destroy: false,
          manage_permissions: false,
        },
        documents: {
          see: false,
          create: false,
          update: false,
          destroy: false,
          manage_permissions: false,
        },
        kpis: {
          see: false,
          create: false,
          update: false,
          destroy: false,
          manage_permissions: false,
        },
        price_lists: {
          see: false,
          create: false,
          update: false,
          destroy: false,
          manage_permissions: false,
        },
        warehouses: {
          see: false,
          create: false,
          update: false,
          destroy: false,
          manage_permissions: false,
        },
        roles: {
          see: false,
          create: false,
          update: false,
          destroy: false,
          manage_permissions: false,
        },
        automated_actions: {
          see: false,
          create: false,
          update: false,
          destroy: false,
          manage_permissions: false,
        },
        template_assignments: {
          see_in_object: false,
          update_in_object: false,
          manage_permissions: false,
        },
        application_parts: { see: false, manage_permissions: false },
        issue_notes: { private: false },
        issues_without_service: { see: false },
        raw_data: { see: false },
        configuration: { update: false },
      },
    },
    params: {},
  };

  static tableHeaders = [{ textKey: "form.label.name", value: "name" }];

  static show = {
    buttons: ["edit"],
    submenuActions: ["duplicate", "permittedPersons", "activity", "destroy"],
    reloadingModels: ["person_role"],
  };

  static filter = {
    parts: {
      fields: ["name", "description", "time_filter"],
      extended_fields: [],
      time_filter: ["created_at", "updated_at"],
    },
  };

  static basicInfo(object) {
    return [
      {
        labelKey: "form.label.created_at",
        value: object.created_at,
        type: "date_time",
      },
    ];
  }

  static tabs = [
    this.detailTab,
    {
      titleKey: "role.role",
      permission: "roles_tab_role",
      component: "RoleTab",
      input_data: (context) => context.object,
      parameters_f() {},
      disabled: (context) => context.dataShow_disableManagePermissions,
    },
    {
      titleKey: "permission.permissions",
      permission: "roles_tab_permissions",
      component: "RolePermissionsTab",
      input_data: (context) => context.object,
      parameters_f() {},
      disabled: (context) => context.dataShow_disableManagePermissions,
    },
    {
      titleKey: "person.persons",
      permission: "roles_tab_persons",
      component: "PersonRoleTab",
      input_data: (context) => context.object.person_roles,
      parameters_f: (context) => ({
        role_id: context.object.id,
      }),
      disabled: (context) => context.dataShow_disableUpdate,
    },
    {
      titleKey: "service.label.workflow",
      permission: "roles_tab_issue_state_transitions",
      component: "IssueStateTransitionsRoleTab",
      input_data() {},
      parameters_f() {},
      disabled: (context) => context.dataShow_disableUpdate,
    },
  ];
}

export default Role;
