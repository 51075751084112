<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <base-v-card-title-dialog-intern
        :label="header_message"
        @input="dialog = false"
      ></base-v-card-title-dialog-intern>

      <v-card-text v-if="params.model" class="pt-6">
        <v-alert outlined type="warning" text>
          {{ $t(`${params.model}.${params.model}`) }}
          <strong v-if="params.name">{{ params.name }}</strong>
          {{ $t("messages.will_permanently") }}
          {{ $t(`${params.model}.alert.destroy`) }}.
        </v-alert>
      </v-card-text>

      <base-v-card-actions-buttons-dialog
        positive-translation="action.delete"
        positive-color="error"
        negative-translation="action.cancel"
        :loading="running"
        @positive="delete_object"
        @negative="dialog = false"
      ></base-v-card-actions-buttons-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import BasicMixin from "../../mixins/BasicMixin";
import BaseVCard from "./base/BaseVCard";

export default {
  name: "DeleteDialog",
  mixins: [BasicMixin, BaseVCard],
  data: () => ({
    dialog: false,
    params: {},
    running: false,
  }),
  computed: {
    header_message() {
      if (this.params.model) {
        return `${this.$t("action.delete")} ${this.$t(
          `${this.params.model}.acc`
        )}?`;
      } else {
        return "";
      }
    },
    actions() {
      return this.$store.state.deleting.actions;
    },
  },
  watch: {
    dialog(newVar) {
      if (!newVar) {
        // clear if close
        this.params = {};
      }
    },
    actions() {
      if (Object.keys(this.params).length === 0) {
        this.params = this.actions[0];
        this.dialog = true;
        this.$store.commit("deleting/remove_action");
      }
    },
  },
  methods: {
    delete_object() {
      this.running = true;
      // close dialog
      this.$http
        .delete(
          `${this.modelPluralize(this.params.model)}/${this.params.id}`,
          {}
        )
        .then(
          (response) => {
            this.success_message("destroy", this.params.model);

            if (this.params.redirect) {
              this.$router.push({
                path: `/${this.params.redirect}`,
                query: this.$route.query,
              });
            }
            this.$store.dispatch("loadable/resetItems", this.params.model);
            this.$store.commit("reloading/reload", {
              reload: this.params.model,
              data: response.body,
            });
            this.$store.commit("deleting/add_item", {
              id: this.params.id,
              model: this.params.model,
            });
            this.running = false;
            this.dialog = false;
          },
          (response) => {
            this.error_message("delete", this.params.model, response);
            this.running = false;
          }
        );
    },
  },
};
</script>

<style scoped></style>
