const TabsMixin = {
  props: {
    tab: Number,
    klass: Function,
    input_data: {
      type: [Object, Array],
      default: () => {},
    },
    parameters: Object,
    active: Boolean,
    disabled: Boolean,
  },
  data: () => ({
    new_object: {},
    totalObjects: 0,
    loading: false,
    clientPagination: false,
    changeRoute: false,
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ["created_at"],
      sortDesc: [true],
    },
  }),
  methods: {
    fetchData() {},
    delete_object(id, name) {
      const params = {
        id: id,
        name: name,
        model: this.model,
      };

      this.$store.commit("deleting/add_action", params);
    },
    newModelText(modelSingular) {
      return (
        this.$t(`${modelSingular}.alert.new`) +
        " " +
        this.$t(`${modelSingular}.${modelSingular}`)
      );
    },
  },
  watch: {
    "$route.params.id"() {
      this.changeRoute = true;
      this.objects = [];
      this.new_object = {};
      this.totalObjects = 0;
      if (this.options.page === 1 && this.active) {
        this.fetchData();
        this.changeRoute = false;
      } else {
        this.options.page = 1;
        if (this.clientPagination && this.active) {
          this.fetchData();
          this.changeRoute = false;
        }
      }
    },
    options: {
      handler() {
        if (!this.clientPagination) {
          this.fetchData();
          this.changeRoute = false;
        }
      },
      deep: true,
    },
    tab() {
      if (this.active && this.changeRoute) {
        this.fetchData();
        this.changeRoute = false;
      }
    },
  },
};

export default TabsMixin;
