import _DatabaseModel from "./_DatabaseModel";

class PriceList extends _DatabaseModel {
  static model = "price_list";
  static showSize = 8;
  static editDialogComponent = "price-lists-edit-dialog";

  static tableHeaders = [
    { textKey: "form.label.name", value: "name" },
    { text: "", value: "tags", sortable: false },
  ];

  static show = {
    buttons: ["edit"],
    submenuActions: [
      "duplicate",
      "permittedPersons",
      "export",
      "activity",
      "destroy",
    ],
    reloadingModels: ["price_items_price_list"],
  };

  static filter = {
    parts: {
      fields: ["name", "description", "time_filter", "currency"],
      extended_fields: [],
      time_filter: ["created_at", "updated_at"],
    },
  };

  static operations = ["report"];

  static currencies = ["EUR", "USD", "GBP", "CHF", "CZK"];

  static cardFields(object) {
    return {
      title: object.name,
      subtitle: object.currency,
      tags: true,
    };
  }

  static basicInfo(object) {
    return [
      {
        labelKey: "price_list.label.currency",
        value: object.currency,
        type: "plain",
      },
      {
        labelKey: "form.label.created_at",
        value: object.created_at,
        type: "date_time",
      },
    ];
  }

  static tabs = [
    this.detailTab,
    {
      titleKey: "price_item.price_items",
      permission: "price_lists_tab_items",
      component: "PriceItemsPriceListsTab",
      input_data: (context) => context.object.price_items_price_lists || [],
      parameters_f: (context) => ({ currency: context.object.currency }),
      disabled: (context) => context.dataShow_disableUpdate,
    },
  ];
}

export default PriceList;
