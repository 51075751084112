import _DatabaseModel, { i18n } from "./_DatabaseModel";

class PrimaryAsset extends _DatabaseModel {
  static model = "primary_asset";
  static showSize = 8;
  static editDialogComponent = "primary-assets-edit-dialog";

  static createNewObject = { object: { cia: {} } };

  static tableHeaders = [
    { textKey: "form.label.name", value: "asset.name", noLink: true },
  ];

  static show = {
    buttons: ["edit"],
    submenuActions: ["activity", "destroy"],
    reloadingModels: ["primary_asset_group"],
  };

  static filter = {
    parts: {
      fields: ["name", "time_filter"],
      extended_fields: [],
      time_filter: ["created_at", "updated_at"],
    },
  };

  static cardFields(object) {
    return {
      title: object.asset.name,
    };
  }

  static operations = ["export"];

  static confidentiality_types = [
    "public",
    "internal",
    "protected",
    "strictly_protected",
  ];
  static integrity_types = ["low", "medium", "high"];
  static availability_types = ["low", "medium", "high"];

  static basicInfo(object) {
    return [
      {
        labelKey: "form.label.created_at",
        value: object.created_at,
        type: "date_time",
      },
      {
        labelKey: "form.label.confidentiality",
        value: i18n.t(
          `primary_asset.confidentiality_types.${object.cia.confidentiality}`
        ),
        type: "plain",
      },
      {
        labelKey: "form.label.integrity",
        value: i18n.t(`primary_asset.integrity_types.${object.cia.integrity}`),
        type: "plain",
      },
      {
        labelKey: "form.label.availability",
        value: i18n.t(
          `primary_asset.availability_types.${object.cia.availability}`
        ),
        type: "plain",
      },
    ];
  }

  static tabs = [
    this.detailTab,
    {
      titleKey: "asset_group.asset_groups",
      permission: "primary_asset_tab_asset_groups",
      component: "AssetGroupsTab",
      input_data: (context) => context.object.primary_asset_groups,
      parameters_f() {},
      disabled: () => false,
    },
  ];
}

export default PrimaryAsset;
