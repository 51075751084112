<template>
  <base-edit-dialog
    :dialog.sync="dialog"
    :model="model"
    :edit-mode="edit_mode"
    :loading="actionsMixin_running"
    @positive="save"
  >
    <v-form ref="form">
      <v-text-field
        v-model="edit_object.name"
        :label="$t('form.label.name')"
        type="text"
        :rules="customRules.field_min_1_max_255"
        validate-on-blur
      />

      <text-area-with-menu
        v-model="edit_object.description"
        :label="$t('form.label.description')"
        apply-snippets
      />

      <v-select
        v-model="edit_object.target_entity"
        :items="dataSet.translatedSelect('target_entities')"
        :label="$t('data_set.label.target_entity')"
        :rules="customRules.field_required"
        validate-on-blur
        required
      />

      <v-checkbox
        v-model="edit_object.public"
        :label="$t('data_set.label.public')"
      />

      <v-tabs v-model="tab" slider-color="primary" grow>
        <v-tab>{{ $t("data_set.label.form") }}</v-tab>

        <v-tab>Json</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item>
          <base-filter-inner
            v-if="edit_object.target_entity"
            v-model="edit_object.definition"
            :model="klass.model"
            :parts="klass.filter.parts"
            :selected-fields="sendFields"
            :klass="klass"
            :filter-drawer="dialog"
          />
        </v-tab-item>
        <v-tab-item>
          <v-textarea
            :key="tab"
            v-model="definition_str"
            class="text-monospace"
            filled
            :label="$t('data_set.label.definition')"
            :rules="customRules.field_json"
            validate-on-blur
            rows="10"
            @keydown.tab.prevent="
              dataEdit_textAreaTabPress($event, 'definition_str')
            "
            @input="jsonWizardEditMixin_inputJson($event)"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-form>
  </base-edit-dialog>
</template>

<script>
import BaseEditDialog from "../../../_generic/pages/components/base/BaseEditDialog";
import DataEdit from "../../../_generic/mixins/DataEdit";
import FilteringMixinFixQuery from "../../mixins/FilteringMixinFixQuery";
import TextAreaWithMenu from "../components/searcher/TextAreaWithMenu";
import JsonWizardEditMixin from "../../mixins/JsonWizardEditMixin";
import ClassesMixin from "../../../_generic/mixins/ClassesMixin";
import BaseFilterInner from "../../../_generic/pages/components/base/BaseFilterInner";
import DataSet from "../../../_generic/classes/DataSet";

export default {
  name: "DataSetsEditDialog",
  components: {
    BaseEditDialog,
    BaseFilterInner,
    TextAreaWithMenu,
  },
  mixins: [DataEdit, FilteringMixinFixQuery, JsonWizardEditMixin, ClassesMixin],
  props: {
    selectedFields: Array,
  },
  data: () => ({
    model: "data_set",
    dataSet: DataSet,
    definition_str: "",
    tab: 0,
  }),
  computed: {
    klass() {
      return this[this.edit_object.target_entity];
    },
    fields() {
      const filterParts = this.klass.filter.parts;
      return filterParts.fields.concat(filterParts.extended_fields);
    },
    sendFields() {
      return this.selectedFields || this.fields;
    },
  },
  watch: {
    "edit_object.definition": {
      handler() {
        if (this.tab === 0) {
          this.jsonWizardEditMixin_inputWizard();
        }
      },
      deep: true,
    },
  },
  methods: {
    openDialog(editObject, params, editMode) {
      this.dialog = true;
      this.params = params;
      this.edit_mode = editMode;
      this.edit_object = JSON.parse(JSON.stringify(editObject));

      this.edit_object.definition = this.filteringMixin_fixData(
        this.edit_object.definition
      );
      this.jsonWizardEditMixin_inputWizard();
    },
    save() {
      for (const key of Object.keys(this.edit_object.definition)) {
        if (
          (Array.isArray(this.edit_object.definition[key]) &&
            this.edit_object.definition[key].length === 0) ||
          this.edit_object.definition[key] === null ||
          this.edit_object.definition[key] === undefined ||
          this.edit_object.definition[key] === "" ||
          (typeof this.edit_object.definition[key] === "object" &&
            Object.keys(this.edit_object.definition[key]).length === 0)
        ) {
          delete this.edit_object.definition[key];
        }
      }
      this.dataEdit_defaultSave();
    },
  },
};
</script>

<style scoped></style>
