<template>
  <div>
    <div class="text-right mr-12">
      <v-menu
        offset-y
        left
        origin="center center"
        transition="scale-transition"
        :close-on-content-click="false"
      >
        <template #activator="{ on }">
          <v-btn color="primary" class="mt-3" fab small absolute v-on="on">
            <v-icon>{{ mdiMapLegend }}</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-text class="py-2">
            <div class="text-subtitle-1 text-center">
              {{ $t("location.label.layers") }}
            </div>

            <v-switch
              v-for="l in ['basic', 'cadastre']"
              :key="l"
              v-model="layers[l]"
              :label="$t(`location.label.${l}`)"
              hide-details
              dense
              class="mt-2"
            ></v-switch>
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
    <vl-map
      ref="myMap"
      :load-tiles-while-animating="true"
      :load-tiles-while-interacting="true"
      :style="{ height: height }"
      @singleclick="openOverlay($event)"
    >
      <v-menu v-if="overlay.length > 0" v-model="displayOverlay" attach="">
        <v-card
          v-if="isIssues || isCalendar"
          class="pa-2"
          width="330px"
          style="overflow: auto"
          max-height="40vh"
        >
          <div v-for="item in overlay" :key="item.properties.id">
            <drag
              :transfer-data="{
                issue_id: item.properties.id,
                edit_mode: false,
              }"
              :draggable="draggable"
            >
              <v-tooltip
                bottom
                open-delay="500"
                :disabled="draggable || isIssues"
              >
                <template #activator="{ on }">
                  <span v-on="on">
                    <issue-card
                      :item="item.properties.item"
                      :draggable="draggable"
                      @selected-item="$emit('selected-item', $event)"
                    ></issue-card>
                  </span>
                </template>
                <span> {{ $t("calendar.drag_tooltip") }} </span>
              </v-tooltip>
            </drag>
          </div>
        </v-card>

        <v-card
          v-else
          max-width="330px"
          class="pa-2"
          style="overflow-y: auto; min-width: 330px"
          max-height="40vh"
        >
          <div v-for="item in overlay" :key="item.properties.id">
            <base-card-component
              :klass="klass"
              :item="item.properties.item"
              @selected-item="$emit('selected-item', $event)"
            ></base-card-component>
          </div>
        </v-card>
      </v-menu>
      <vl-view
        ref="vl_view"
        :zoom.sync="inner.zoom"
        :center.sync="inner.center"
        :min-zoom="1"
        :max-zoom="18"
      ></vl-view>

      <vl-layer-tile :visible="layers.basic">
        <vl-source-osm></vl-source-osm>
      </vl-layer-tile>

      <vl-layer-tile id="wmts" :visible="layers.cadastre">
        <vl-source-wmts
          url="https://kataster.skgeodesy.sk/eskn/rest/services/NR/kn_wmts_norm_wm/MapServer/WMTS"
          layer-name="WMS_zbgis_wmts_new"
          matrix-set="default028mm"
          format="image/jpgpng"
          style-name="default"
        ></vl-source-wmts>
      </vl-layer-tile>

      <vl-layer-vector :z-index="10000">
        <vl-source-cluster :distance="40">
          <vl-source-vector ref="mySource" :features.sync="inner.features">
          </vl-source-vector>
          <vl-style-func :function="clusterStyleFunc"></vl-style-func>
        </vl-source-cluster>
      </vl-layer-vector>
    </vl-map>
  </div>
</template>

<script>
import { createStyle } from "vuelayers/dist/ol-ext";
import BasicMixin from "../../../_generic/mixins/BasicMixin";
import IssueCard from "../issues/IssueCard";
import { mdiMapMarker, mdiClose, mdiMapLegend } from "@mdi/js";
import BaseCardComponent from "../../../_generic/pages/components/base/BaseCardComponent";

export default {
  name: "CustomMap",
  components: { BaseCardComponent, IssueCard },
  mixins: [BasicMixin],
  props: {
    klass: Function,
    height: String,
    features: {
      type: Array,
      default: () => {
        return [];
      },
    },
    center: Array,
    zoom: [Number],
    saveZoom: Boolean,
    saveCenter: Boolean,
    centerMarkers: Boolean,
    noOverlay: Boolean,
    draggable: Boolean,
  },
  data: () => ({
    inner: {
      zoom: 7,
      center: [19.269288, 48.774457],
      features: [],
    },
    displayOverlay: false,
    overlay: [],
    menu: false,
    mdiClose,
    mdiMapLegend,
    layers: { basic: true, cadastre: false },
  }),
  computed: {
    isIssues() {
      return (
        this.$route.path === "/issues" ||
        this.$route.path === `/issues/${this.$route.params.id}` ||
        (this.klass && this.isModel("issue"))
      );
    },
    isCalendar() {
      return this.$route.path === "/planning_calendar";
    },
  },
  watch: {
    klass() {
      this.inner.features = [];
    },
    features: {
      handler() {
        this.$nextTick(() => {
          if (this.features.length > 0) {
            // nextThick for mount this.$refs.mySource
            this.overlay = [];
            // reset zoom on data change
            if (!this.saveZoom) {
              this.$set(this.inner, "zoom", this.zoom);
            }
            this.$set(this.inner, "features", this.features);
            // call compute center when source is mounted
            if (this.$refs.mySource) {
              this.$refs.mySource.$mountPromise.then(() => {
                this.computeCenter();
              });
            }
            // reset center
            if (
              (!this.saveCenter && !this.centerMarkers) ||
              (this.inner.center[0] === 0 && this.inner.center[1] === 0)
            ) {
              this.$set(this.inner, "center", this.center);
            }
          } else {
            // fix for map markers refresh when features count change from n to 0 ( e.g. filter )
            if (this.$refs.mySource) this.$refs.mySource.clear();
          }
        });
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async openOverlay(event) {
      const x = event.pixel[0];
      const y = event.pixel[1];

      const selectedFeatures = await this.$refs.myMap.forEachFeatureAtPixel(
        [x - 4, y - 4],
        (feature) => {
          return feature.get("features");
        },
        { hitTolerance: 7 }
      );

      if (selectedFeatures) {
        this.overlay = [];
        // find model feature
        for (let i = 0; i < selectedFeatures.length; i++) {
          const overlayFeature = this.inner.features.find((item) => {
            return item.id === selectedFeatures[i].getId();
          });

          if (!this.noOverlay) {
            this.overlay[i] = {
              properties: overlayFeature.properties,
            };
            this.displayOverlay = true;
          }
        }
      }
      // pass event
      this.$emit("map-single-click", event.coordinate);
    },
    clusterStyleFunc(feature) {
      const size = feature.get("features").length;
      let style;

      if (size === 1) {
        const img = !feature.get("features")[0].values_.img
          ? mdiMapMarker
          : feature.get("features")[0].values_.img;
        style = createStyle({
          imageAnchor: [0.5, 0.9],
          imageSize: [24, 24],
          imageScale: 1.5,
          imageSrc: `data:image/svg+xml;charset=UTF-8,
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"
                  fill="${feature
                    .get("features")[0]
                    .get("color")
                    .replace("#", "%23")}"><path d="${img}"/></svg>`,
        });
        return style;
      } else {
        let color = "";
        if (feature.get("features")[0].get("issue_priority_id")) {
          color = feature.get("features")[0].get("color");
        } else {
          for (let i = 0; i < feature.get("features").length; i++) {
            if (
              feature.get("features")[i].get("color") === "#00f" ||
              feature.get("features")[i].get("color") === "blue"
            ) {
              color = "#00f";
              break;
            } else {
              color = "#f00";
            }
          }
        }
        style = createStyle({
          imageRadius: 18,
          imageStroke: "#fff",
          imageFillColor: color,
          text: size.toString(),
          textFillColor: "#fff",
          textFont: "bold 14px Roboto, sans-serif",
        });
        return style;
      }
    },
    computeCenter() {
      // compute center to fit all markers
      if (this.centerMarkers && this.features.length > 0) {
        const extent = this.$refs.mySource.$source.getExtent();

        this.$refs.vl_view.fit(extent, {
          padding: [50, 15, 20, 15],
          constrainResolution: false,
        });

        if (this.inner.zoom > 18) {
          this.$set(this.inner, "zoom", 18);
        }
      } else {
        this.$set(this.inner, "center", this.center);
      }
    },
  },
};
</script>

<style scoped></style>
