<template>
  <v-data-table
    :value="innerSelected"
    :headers="translatedHeaders"
    :items="objects"
    :options.sync="innerOptions"
    :custom-sort="
      (items) => {
        return items;
      }
    "
    hide-default-footer
    disable-pagination
    :loading="loading"
    :loading-text="$t('dataTableMessages.emptyData.loading')"
    :single-select="!!klass.show"
    :show-expand="isModel('activity', 'background_job')"
    :mobile-breakpoint="
      isModel('activity', 'background_job', 'requirement') ? 0 : undefined
    "
    dense
    class="pb-3"
    :expanded="[]"
    :class="{ 'clickable-table-row': klass.show }"
    @click:row="selectRow"
  >
    <template #no-data>
      <base-empty-data-alert
        :name="$t(`${klass.model}.${modelPluralize(klass.model)}`)"
      />
    </template>

    <template #item.name="{ item, header }">
      <v-tooltip v-if="header.tag" bottom :open-delay="500">
        <template #activator="{ on }">
          <v-chip
            :color="`${item.color}`"
            small
            style="max-width: 205px"
            :class="textColor(item.color)"
            v-on="on"
          >
            <span class="text-truncate">{{ item.name }}</span>
          </v-chip>
        </template>
        <span>{{ item.name }}</span>
      </v-tooltip>
      <issue-state-tag v-else-if="header.stateTag" :state="item" />
      <issue-priority-tag v-else-if="header.priorityTag" :priority="item" />
      <span v-else-if="header.translated">{{ getTranslation(item.name) }}</span>
      <span v-else>{{ item.name }}</span>
    </template>

    <template #item.issue_state_id="{ item }">
      <issue-state-tag
        v-if="item.issue_state"
        :state="item.issue_state"
      ></issue-state-tag>
    </template>

    <template #item.issue_priority_id="{ item }">
      <issue-priority-tag
        v-if="item.issue_priority"
        :priority="item.issue_priority"
      ></issue-priority-tag>
    </template>

    <template #item.issue.name="{ item }">
      <link-checked
        :id="item.issue.id"
        to="issues"
        :text="`#${item.issue.id} ${item.issue.name}`"
        :discarded-at="item.issue.discarded_at"
      ></link-checked>
    </template>

    <template #item.asset.name="{ item, header }">
      <link-checked
        :id="item.asset.id"
        to="assets"
        :text="`${item.asset.name}`"
        :disabled="header.noLink"
        :discarded-at="item.asset.discarded_at"
      ></link-checked>
    </template>

    <template #item.description="{ item, header }">
      <span v-if="header.translated">{{
        getTranslation(item.description)
      }}</span>
      <span v-else>{{ item.description }}</span>
    </template>

    <template #item.hidden="{ item }">
      {{ basicMixin_translateTrueFalse(item.hidden) }}
    </template>

    <template #item.tags="{ item }">
      <base-object-tags
        :model="klass.model"
        bottom-margin
        top-margin
        :object="item"
      />
    </template>

    <template
      v-for="dateTime in dateTimeSlots"
      #[`item.${dateTime}`]="{ item }"
    >
      <base-tooltip-date-data :key="dateTime" :datetime="item[dateTime]" />
    </template>

    <template #item.created_by="{ item }">
      <link-checked
        :id="item.author_id"
        :to="toModel(item.author_type, true)"
        :text="authorName(item.author, item.author_type, item.author_id)"
        :discarded-at="item.author && item.author.discarded_at"
        :disabled="!item.author"
      />
    </template>

    <template #item.creator="{ item }">
      <link-checked
        v-if="item.creator"
        :id="item.creator.id"
        :to="toModel(item.creator_type, true)"
        :text="authorName(item.creator, item.creator_type, item.creator.id)"
        :discarded-at="item.creator.discarded_at"
      />
    </template>

    <template #item.issue_assignees="{ item }">
      <link-checked
        v-for="(person, i) in item.persons"
        :id="person.id"
        :key="i"
        to="persons"
        :text="person.name"
        :discarded-at="person.discarded_at"
        :append-text="i !== item.persons.length - 1 ? ',' : ''"
      ></link-checked>
    </template>

    <template #item.assets="{ item }">
      <div class="d-flex flex-wrap">
        <link-checked
          v-for="(asset, i) in item.assets?.slice(0, 2)"
          :id="asset.id"
          :key="asset.id"
          to="assets"
          :text="asset.name"
          :discarded-at="asset.discarded_at"
          :append-text="i === 0 && item.assets.length > 1 ? ',' : ''"
        ></link-checked>
        <div v-if="item.assets?.length > 2" class="mr-2 mb-2">
          +{{ item.assets.length - 2 }}
        </div>
      </div>
    </template>

    <template v-for="slot in dateSlots" #[`item.${slot}`]="{ item }">
      <template v-if="item[slot]">
        {{ item[slot] | moment(date_format()) }}
      </template>

      <template v-else>
        {{ $t("generic.unset") }}
      </template>
    </template>

    <template #item.notification_time="{ item }">
      <base-tooltip-date-data
        :datetime="item.notification_time"
      ></base-tooltip-date-data>
    </template>

    <template
      v-for="h in Object.entries(notificationIconHeaders)"
      #[`header.${h[0]}`]
    >
      <base-tooltip-icon
        :key="h[0]"
        :icon="h[1].icon"
        :label="$t(`notification.tooltip.${h[0]}`)"
      ></base-tooltip-icon>
    </template>

    <template
      v-for="notification in Object.entries(notificationIconHeaders)"
      #[`item.${notification[0]}`]="{ item }"
    >
      <base-table-boolean
        :key="notification[0]"
        :value="basicMixin_getSafe(() => item[notification[1].model], false)"
      ></base-table-boolean>
    </template>

    <template #item.doc_file_size="{ item }">
      {{ convertFileSize(item.doc_file_size) }}
    </template>

    <template #item.acceptable="{ item }">
      <base-table-boolean
        :value="basicMixin_getSafe(() => item.acceptable, false)"
      ></base-table-boolean>
    </template>

    <template
      v-for="translate of translateDefault"
      #[`item.${translate}`]="{ item, header }"
    >
      {{
        basicMixin_translateDefault(
          `${header.translationPrefix}.${item[translate]}`,
          item[translate]
        )
      }}
    </template>

    <template #item.entity_type="{ item }">
      {{
        basicMixin_translateDefault(
          `${toModel(item.entity_type)}.${toModel(item.entity_type)}`,
          item.entity_type
        )
      }}
    </template>

    <template #item.subscription_type="{ item }">
      {{
        basicMixin_translateDefault(
          `${toModel(item.subscription_type)}.${toModel(
            item.subscription_type
          )}`,
          item.subscription_type
        )
      }}
    </template>

    <template #item.connector_status="{ item }">
      <v-icon :color="klass.cardFields(item).src.color">
        {{ klass.cardFields(item).src.cardIcon }}
      </v-icon>
    </template>

    <template
      v-for="i of ['on', 'public', 'otp_required_for_login']"
      #[`item.${i}`]="{ item }"
    >
      {{ basicMixin_translateTrueFalse(item[i]) }}
    </template>

    <template #item.target_entity="{ item }">
      {{
        $t(
          item.target_entity === "assets_issue"
            ? "checklist.checklist"
            : `${toModel(item.target_entity)}.${toModel(item.target_entity)}`
        )
      }}
    </template>

    <template #item.activity_type="{ item }">
      <v-chip
        v-if="item.activity_type"
        class="white--text"
        small
        :class="activityMixins_activityColor(item.activity_type)"
      >
        {{ $t(`activity.activity_types.${item.activity_type}`) }}
      </v-chip>
    </template>

    <template #item.entity_id="{ item }">
      {{ item.entity_id }}
    </template>

    <template #item.consequence="{ item }">
      <span>{{
        item.consequence ? item.consequence.name : $t(`risk.label.not_rated`)
      }}</span>
    </template>
    <template #item.likelihood="{ item }">
      <span>{{
        item.likelihood ? item.likelihood.name : $t(`risk.label.not_rated`)
      }}</span>
    </template>

    <template #item.estimated_budget="{ item }">
      <span>{{ $n(item.estimated_budget, "currency") }} </span>
    </template>

    <template #item.data-table-expand="{ item, isExpanded, expand }">
      <v-icon
        v-if="klass.isExpandable(item)"
        :class="{ rot: isExpanded }"
        @click="expandItem(item, isExpanded, expand, klass.model)"
        >{{ mdiChevronDown }}</v-icon
      >
    </template>

    <template #expanded-item="{ item, headers: tableHeaders }">
      <td :colspan="tableHeaders.length">
        <activity-diff-card
          v-if="isModel('activity')"
          :activity="item.object"
        />
        <background-job-expand-detail v-else :item="item" />
      </td>
    </template>

    <template #item.actions="{ item }">
      <div class="d-flex justify-end">
        <base-icon-button
          v-if="item.order !== 0 && isModel('issue_state', 'issue_priority')"
          :icon="mdiArrowUpBold"
          :tooltip-translation="
            $t(
              `tooltips.tabs_icons.${
                isModel('issue_priority') ? 'priority_' : ''
              }move_up`
            )
          "
          :tooltip-disabled-translation="
            $t('tooltips.show_buttons.disabled_buttons.generic')
          "
          :disabled="configurationDisabled"
          @click="moveItem(item, 'up')"
        ></base-icon-button>

        <v-spacer
          v-if="item.order === objects.length - 1"
          style="width: 40px; max-width: 40px"
          class="order-1"
        ></v-spacer>
        <base-icon-button
          v-if="
            item.order !== objects.length - 1 &&
            isModel('issue_state', 'issue_priority')
          "
          :icon="mdiArrowDownBold"
          :tooltip-translation="
            $t(
              `tooltips.tabs_icons.${
                isModel('issue_priority') ? 'priority_' : ''
              }move_down`
            )
          "
          :tooltip-disabled-translation="
            $t('tooltips.show_buttons.disabled_buttons.generic')
          "
          :disabled="configurationDisabled"
          @click="moveItem(item, 'down')"
        ></base-icon-button>

        <div class="order-2">
          <base-icon-button
            v-if="!['subscription'].includes(klass.model)"
            :icon="mdiPencil"
            :tooltip-translation="$t('tooltips.tabs_icons.edit')"
            :tooltip-disabled-translation="
              isModel('reminder')
                ? $t('tooltips.show_buttons.disabled_buttons.reminder_exp')
                : $t('tooltips.show_buttons.disabled_buttons.generic')
            "
            :disabled="
              (isModel('reminder') && item.notification_time < now) ||
              configurationDisabled
            "
            @click.stop="editItem(item)"
          ></base-icon-button>

          <base-icon-button
            :icon="mdiDelete"
            color="error"
            :tooltip-translation="$t('tooltips.tabs_icons.delete')"
            :tooltip-disabled-translation="
              $t('tooltips.show_buttons.disabled_buttons.generic')
            "
            :disabled="configurationDisabled"
            @click.stop="deleteItem(item)"
          ></base-icon-button>
        </div>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import BasicMixin from "../../../mixins/BasicMixin";
import ColorHelper from "../../../mixins/ColorHelper";
import BaseTableBoolean from "./BaseTableBoolean";
import ActivityDiffCard from "../../../../_functions/pages/activities/components/ActivityDiffCard";
import LinkChecked from "../../../../_functions/pages/components/LinkChecked";
import BaseTooltipIcon from "./BaseTooltipIcon";
import BaseTooltipDateData from "./BaseTooltipDateData";
import BaseObjectTags from "./BaseObjectTags";
import IssueStateTag from "../../../../_functions/pages/issues/components/IssueStateTag";
import IssuePriorityTag from "../../../../_functions/pages/issues/components/IssuePriorityTag";
import BaseEmptyDataAlert from "./BaseEmptyDataAlert";
import BackgroundJobExpandDetail from "../../../../_functions/pages/background_jobs/components/BackgroundJobExpandDetail";
import ActivityMixins from "../../../../_functions/mixins/ActivityMixins";
import PrintingDate from "../../../mixins/PrintingDate";
import {
  mdiCellphone,
  mdiEmail,
  mdiDelete,
  mdiPencil,
  mdiCheckCircle,
  mdiArrowDownBold,
  mdiArrowUpBold,
  mdiChevronDown,
  mdiPlay,
  mdiMessageText,
} from "@mdi/js";
import BaseIconButton from "./BaseIconButton";
export default {
  name: "BaseDatatable",
  components: {
    BaseIconButton,
    BaseTooltipIcon,
    BaseObjectTags,
    BaseTooltipDateData,
    BaseTableBoolean,
    BaseEmptyDataAlert,
    IssueStateTag,
    IssuePriorityTag,
    LinkChecked,
    ActivityDiffCard,
    BackgroundJobExpandDetail,
  },
  mixins: [BasicMixin, ActivityMixins, PrintingDate, ColorHelper],
  props: {
    loading: Boolean,
    klass: Function,
    objects: Array,
    options: Object,
    selectedItem: Array,
    deleteItem: Function,
    editItem: Function,
    moveItem: Function,
    headers: Array,
    now: String,
  },
  data() {
    return {
      translateDefault: [
        "automated_action_type",
        "run_type",
        "connector_type",
        "mapper_type",
        "calculation",
        "visualization",
        "price_type",
        "service_type",
        "tier",
        "status",
        "solve_status",
        "data_type",
      ],
      dateSlots: [
        "valid_from",
        "valid_to",
        "effective_from",
        "effective_to",
        "date",
        "resolve_at",
      ],
      dateTimeSlots: [
        "created_at",
        "updated_at",
        "run_at",
        "failed_at",
        "discarded_at",
      ],
      notificationIconHeaders: {
        mail: { icon: mdiEmail, model: "mail" },
        push: { icon: mdiCellphone, model: "push" },
        sms: { icon: mdiMessageText, model: "sms" },
      },
      mdiDelete,
      mdiPencil,
      mdiCheckCircle,
      mdiArrowDownBold,
      mdiArrowUpBold,
      mdiChevronDown,
      mdiPlay,
    };
  },
  computed: {
    translatedHeaders() {
      let h = this.klass.tableHeaders.map((item) => {
        if (item.textKey) {
          item.text = this.$t(item.textKey);
        }
        return item;
      });
      if (!this.$route.path.includes("dashboard_screens")) {
        h = h.filter(
          (item) =>
            item.value !== "discarded_at" ||
            this.$route.query["persistence"] === "1"
        );
      }
      if (this.headers?.length > 0) {
        const filteredHeaders = h.filter((i) => this.headers.includes(i.value));

        const sortedArray = [...filteredHeaders];
        return sortedArray.sort((a, b) => {
          const indexA = this.headers.indexOf(a.value);
          const indexB = this.headers.indexOf(b.value);
          return indexA - indexB;
        });
      } else if (this.$route.path.includes("dashboard_screens")) {
        // data-table-expand is excluded because on mobile view is better default button on left
        return h.filter(
          (i) => !["actions", "data-table-expand"].includes(i.value)
        );
      } else return h;
    },
    innerOptions: {
      get() {
        return this.options;
      },
      set(options) {
        this.$emit("update:options", options);
      },
    },
    innerSelected: {
      get() {
        return this.selectedItem;
      },
      set(selected) {
        this.$emit("update:selected-item", selected);
      },
    },
    configurationDisabled() {
      return (
        this.isConfigurationModel() &&
        !this.$store.state.basic_module.permissions.configuration
      );
    },
  },
  methods: {
    selectRow(item) {
      if (this.klass.show) this.innerSelected = [item];
    },
  },
};
</script>

<style scoped lang="scss">
.rot {
  transform: rotate(-180deg);
}
</style>
