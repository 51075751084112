<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="600"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card class="overflow-auto">
      <base-v-card-title-dialog-intern
        v-model="dialog"
        :model="issue.id ? null : model"
        :label="issue.id ? $t('issue.label.change_state_dialog') : null"
      ></base-v-card-title-dialog-intern>

      <v-card-text class="pa-0">
        <v-stepper v-model="stepper" class="elevation-0">
          <v-stepper-items>
            <v-stepper-content step="1" class="px-0">
              <v-form ref="form_1" class="px-6">
                <v-autocomplete
                  v-model="issue.service_id"
                  :readonly="editMode"
                  :items="services"
                  item-value="id"
                  item-text="name"
                  :filter="customFilter"
                  :label="$t('service.service')"
                  :hint="$t('issue.hint.service')"
                  validate-on-blur
                ></v-autocomplete>

                <div
                  v-if="issue.service_id && next_states.length > 0"
                  class="d-flex justify-center flex-wrap align-center"
                >
                  <div style="width: 100%; text-align: center">
                    <issue-state-tag
                      v-if="next_states[0].from_issue_state_id"
                      :state="issue.issue_state"
                      :small="false"
                    ></issue-state-tag>
                    <div>
                      <v-icon>{{ mdiArrowDown }}</v-icon>
                    </div>
                  </div>

                  <v-item-group
                    v-model="selected_next_state"
                    mandatory
                    class="d-flex flex-wrap justify-center"
                  >
                    <v-item
                      v-for="state in next_states"
                      :key="state.id"
                      v-slot="{ active, toggle }"
                      :value="state"
                    >
                      <issue-state-tag
                        group
                        bottom-margin
                        right-margin
                        :state="state.to_issue_state"
                        :small="false"
                        :active="active"
                        @click="toggle"
                      ></issue-state-tag>
                    </v-item>
                  </v-item-group>
                </div>
              </v-form>
            </v-stepper-content>
            <v-stepper-content v-if="selected_next_state" step="2" class="px-0">
              <v-form ref="form_2" class="px-6">
                <div v-if="isDisplayed('assets')" class="pb-2">
                  <div class="pb-2 text-caption grey--text">
                    {{ $t("asset.select_type") }}
                  </div>

                  <v-btn-toggle v-model="value.select_types" mandatory>
                    <v-btn
                      v-for="(item, key) in basicMixin_toSelect(select_types)"
                      :key="key"
                      :value="item.value"
                      text
                      small
                      active-class="light-active"
                    >
                      {{ item.text }}
                    </v-btn>
                  </v-btn-toggle>

                  <v-select
                    v-if="value.select_types === 'Filters'"
                    v-model="selectedFilter"
                    :items="assetFilters"
                    return-object
                    :label="$t('filter.actions.subheaders.savedFilters')"
                    item-text="name"
                    item-value="id"
                    clearable
                  ></v-select>

                  <base-searcher
                    v-if="value.select_types === 'Search'"
                    type="asset"
                    :model.sync="issue.assets"
                    :label="$t('asset.assets')"
                    multiple
                    :filter="{ service_id: issue.service_id }"
                    :rules="getRule('assets')"
                  ></base-searcher>

                  <v-data-table
                    v-if="!issue.id && existedIssues.length > 0"
                    :items="existedIssues"
                    :headers="issueHeaders"
                    hide-default-footer
                    disable-pagination
                    :mobile-breakpoint="0"
                    dense
                  >
                    <template #item.id="{ item }">
                      <router-link
                        :to="`/issues/${item.id}`"
                        @click.native="$event.stopImmediatePropagation()"
                        >{{ item.id }}</router-link
                      >
                    </template>
                    <template #item.created_at="{ item }">
                      <base-tooltip-date-data :datetime="item.created_at" />
                    </template>
                    <template #item.creator="{ item }">
                      <link-checked
                        v-if="item.creator"
                        :id="item.creator.id"
                        :to="toModel(item.creator_type, true)"
                        :text="
                          authorName(
                            item.creator,
                            item.creator_type,
                            item.creator.id
                          )
                        "
                        :discarded-at="item.creator.discarded_at"
                      />
                    </template>
                  </v-data-table>
                </div>

                <v-text-field
                  v-if="isDisplayed('name')"
                  v-model="issue.name"
                  :label="$t('form.label.name')"
                  type="text"
                  :rules="getRule('name')"
                  validate-on-blur
                ></v-text-field>

                <text-area-with-menu
                  v-if="isDisplayed('description')"
                  v-model="issue.description"
                  :label="$t('form.label.description')"
                  :rules="getRule('description')"
                  apply-snippets
                ></text-area-with-menu>

                <issue-priority-loadable-autocomplete
                  v-if="isDisplayed('priority')"
                  v-model="issue.issue_priority_id"
                  :rules="getRule('priority')"
                  clearable
                  validate-on-blur
                  :on-screen="dialog"
                  :filter="(p) => p.service_ids.includes(issue.service_id)"
                />

                <input-date-picker-dialog
                  v-if="isDisplayed('resolve_at')"
                  v-model="issue.resolve_at"
                  :label="$t('issue.label.resolve_at')"
                  :rules="getRule('resolve_at')"
                  validate-on-blur
                ></input-date-picker-dialog>

                <div v-if="isDisplayed('location')">
                  <v-switch
                    v-model="geoCheck"
                    :label="$t('issue.public_form.location_save')"
                    :disabled="!accessToGeoLocation"
                  ></v-switch>

                  <v-alert
                    v-if="!accessToGeoLocation"
                    text
                    type="info"
                    class="text-body-2 text-center"
                  >
                    {{ $t("issue.public_form.location_info") }}
                  </v-alert>
                </div>

                <v-checkbox
                  v-if="isDisplayed('public_token_valid')"
                  v-model="issue.public_token_valid"
                  :label="$t('issue.label.public_token_valid')"
                ></v-checkbox>
              </v-form>
            </v-stepper-content>

            <v-stepper-content
              v-if="templateAssignments.length > 0"
              step="3"
              class="px-0"
            >
              <div class="px-6 py-1">
                <template-visualization
                  ref="form_3"
                  :key="$route.params.id"
                  v-model="issue.properties"
                  :template-assignments="templateAssignments"
                  expand-first
                ></template-visualization>
              </div>
            </v-stepper-content>

            <v-stepper-content v-if="selected_next_state" step="4" class="px-0">
              <v-form ref="form_4" class="px-6">
                <base-loadable-autocomplete
                  v-if="isDisplayed('service')"
                  v-model="new_service_id"
                  model="service"
                  :label="$t('service.service')"
                  clearable
                  :rules="getRule('service')"
                ></base-loadable-autocomplete>

                <base-searcher
                  v-if="isDisplayed('persons')"
                  type="person"
                  :model.sync="issue.persons"
                  :label="$t('person.persons')"
                  multiple
                  :rules="getRule('persons')"
                ></base-searcher>

                <tag-definition-loadable-autocomplete
                  v-if="isDisplayed('tag_assignments')"
                  v-model="issue.tag_definitions"
                  return-object
                  validate-on-blur
                  multiple
                  clearable
                  :on-screen="dialog"
                  :rules="getRule('tag_assignments')"
                  model="issue"
                />

                <div v-if="isDisplayed('files')">
                  <input-file
                    v-model="files"
                    :label="$t('form.label.files')"
                    :rules="getRule('files')"
                    multiple
                  ></input-file>
                  <base-searcher
                    v-model="filesFolderId"
                    type="document"
                    load-names
                    :label="$t('folder.files_location')"
                    :filter="{ folder: true, create_permitted: true }"
                    :required="files.length > 0"
                  />
                  <v-expansion-panels v-if="documents.length > 0" class="pa-1">
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        :class="{ 'px-3': $vuetify.breakpoint.xsOnly }"
                      >
                        <strong>{{ $t("form.label.files") }}</strong>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content
                        :class="{ panel: $vuetify.breakpoint.xsOnly }"
                      >
                        <v-data-table
                          v-if="documents.length > 0"
                          :headers="headers"
                          :items="documents"
                          :items-per-page="-1"
                          hide-default-footer
                          dense
                        >
                          <template #item.actions="{ item }">
                            <base-icon-button
                              v-if="
                                content_types.includes(
                                  item.document.doc_content_type
                                )
                              "
                              :icon="mdiMagnify"
                              :tooltip-translation="
                                $t('tooltips.tabs_icons.preview')
                              "
                              @click="
                                $refs.preview_dialog.openDialog(
                                  item.document.id,
                                  item.document.doc_content_type
                                )
                              "
                            ></base-icon-button>
                          </template>
                        </v-data-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </v-form>
            </v-stepper-content>

            <v-stepper-content v-if="selected_next_state" step="5" class="px-0">
              <v-form ref="form_5" class="px-6">
                <text-area-with-menu
                  v-if="isDisplayed('solution')"
                  v-model="issue.solution"
                  :label="$t('issue.label.solution')"
                  :rules="getRule('solution')"
                  apply-snippets
                ></text-area-with-menu>

                <div v-if="isDisplayed('note')">
                  <text-area-with-menu
                    v-model="issue.note"
                    :label="$t('issue_note.issue_note')"
                    :hint="$t('issue_note.hint.note')"
                    :rules="getRule('note')"
                    apply-snippets
                  ></text-area-with-menu>

                  <div class="pb-2 text-caption grey--text text-left">
                    {{ $t("issue_note.label.note_type") }}
                  </div>

                  <v-btn-toggle
                    v-model="issue.note_type"
                    mandatory
                    active-class="light-active"
                    class="btn-toggle"
                  >
                    <v-btn
                      v-for="item in notesMixin_noteTypes"
                      :key="item"
                      :value="item"
                      text
                      class="btn-toggle-btn"
                      small
                    >
                      {{ $t(`issue_note.note_types.${item}`) }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </v-form>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>

      <base-v-card-actions-buttons-dialog
        :positive-translation="
          step === getStepper.length - 1 ? 'action.save' : 'action.next'
        "
        :disabled="!selected_next_state || !allowNextStep"
        :negative-translation="step === 1 ? 'action.cancel' : 'action.back'"
        :loading="actionsMixin_running || loading"
        @positive="positiveStep($refs[`form_${stepper}`])"
        @negative="negativeStep()"
      ></base-v-card-actions-buttons-dialog>

      <base-preview-dialog v-if="isDisplayed('files')" ref="preview_dialog" />
    </v-card>
  </v-dialog>
</template>

<script>
import BasicMixin from "../../../../_generic/mixins/BasicMixin";
import RulesMixin from "../../../../_generic/mixins/RulesMixin";
import BaseSearcher from "../../components/searcher/BaseSearcher";
import BaseVCard from "../../../../_generic/pages/components/base/BaseVCard";
import IssueStateTag from "./IssueStateTag";
import InputDatePickerDialog from "../../../../_generic/pages/components/inputs/InputDatePickerDialog";
import GeolocationMixin from "../../../../_generic/mixins/GeolocationMixin";
import InputFile from "../../../../_generic/pages/components/inputs/InputFile";
import NotesMixin from "../../../mixins/NotesMixin";
import TextAreaWithMenu from "../../components/searcher/TextAreaWithMenu";
import { mdiArrowDown, mdiMagnify } from "@mdi/js";
import TemplateVisualization from "../../templates/components/TemplateVisualization";
import TagDefinitionLoadableAutocomplete from "../../tag_definitions/TagDefinitionLoadableAutocomplete.vue";
import BasePreviewDialog from "../../../../_generic/pages/components/base/BasePreviewDialog";
import BaseIconButton from "../../../../_generic/pages/components/base/BaseIconButton";
import IssuePriorityLoadableAutocomplete from "../../issue_states_and_priorities/IssuePriorityLoadableAutocomplete";
import LinkChecked from "../../components/LinkChecked.vue";
import ActivityMixins from "../../../mixins/ActivityMixins";
import BaseTooltipDateData from "../../../../_generic/pages/components/base/BaseTooltipDateData.vue";
import BaseLoadableAutocomplete from "../../../../_generic/pages/components/base/BaseLoadableAutocomplete.vue";

export default {
  name: "IssueChangeState",
  components: {
    BaseLoadableAutocomplete,
    BaseTooltipDateData,
    LinkChecked,
    IssuePriorityLoadableAutocomplete,
    BaseIconButton,
    TagDefinitionLoadableAutocomplete,
    TextAreaWithMenu,
    InputFile,
    InputDatePickerDialog,
    IssueStateTag,
    BaseSearcher,
    TemplateVisualization,
    BasePreviewDialog,
  },
  mixins: [
    BasicMixin,
    RulesMixin,
    BaseVCard,
    GeolocationMixin,
    NotesMixin,
    ActivityMixins,
  ],
  props: {
    noRedirect: Boolean,
  },
  data: () => ({
    model: "issue",
    dialog: false,
    loading: false,
    componentRdy: false,
    next_states: [],
    documents: [],
    stepper: 1,
    step: 1,
    new_service_id: null,
    serviceIdCheck: null,
    selected_next_state: null,
    params: {},
    editMode: false,
    issue: {
      persons: [],
      assets: [],
    },
    files: [],
    filesFolderId: null,
    value: {},
    select_types: {
      Search: "asset.search_assets",
      Filters: "asset.asset_filters",
    },
    selectedFilter: null,
    assetFilters: [],
    existedIssues: [],
    mdiArrowDown,
    mdiMagnify,
    accessToGeoLocation: true,
    geoCheck: false,
    allowNextStep: true,
  }),
  computed: {
    issueHeaders() {
      return [
        { text: this.$t("form.label.id"), value: "id", sortable: false },
        { text: this.$t("form.label.name"), value: "name" },
        { text: this.$t("form.label.created_at"), value: "created_at" },
        {
          text: this.$t("activity.list_label.created_by"),
          value: "creator",
          sortable: false,
        },
      ];
    },
    headers() {
      return [
        {
          text: this.$t("form.label.name"),
          value: "document.name",
        },
        {
          text: this.$t("other.actions"),
          value: "actions",
          sortable: false,
          align: "right",
          cellClass: "text-no-wrap",
        },
      ];
    },
    templateAssignments() {
      if (!this.selected_next_state) return [];

      return this.selected_next_state.service.template_assignments.filter(
        (i) =>
          i.for_checklist === false &&
          this.selected_next_state.definition.template_assignments.includes(
            i.id
          )
      );
    },
    getStepper() {
      const steps = [0, 1];
      if (this.issue.service_id && this.selected_next_state) {
        const display = this.selected_next_state.definition.display;
        const stepInputs = [
          [
            "name",
            "description",
            "priority",
            "resolve_at",
            "location",
            "public_token_valid",
            "assets",
          ],
          "template_assignments",
          ["service", "files", "persons", "tag_assignments"],
          ["note", "solution"],
        ];
        for (const [ind, value] of stepInputs.entries()) {
          if (value === "template_assignments") {
            if (this.templateAssignments.length > 0) steps.push(ind + 2);
          } else {
            if (value.some((i) => display.includes(i))) steps.push(ind + 2);
          }
        }
      }
      return steps;
    },
    services() {
      const allowedTransitions = this.$store.getters["loadable/get_list"](
        "role_issue_state_transition"
      );

      const onlyAllowedService = allowedTransitions
        .filter((item) => !item.issue_state_transition.from_issue_state)
        .map((i) => ({
          id: i.issue_state_transition.service.id,
          name: i.issue_state_transition.service.name,
          service_type: i.issue_state_transition.service.service_type,
        }));

      const noDuplicateServices = Array.from(
        new Set(onlyAllowedService.map((a) => a.id))
      ).map((id) => {
        return onlyAllowedService.find((a) => a.id === id);
      });
      if (this.issue.service_id && this.editMode) {
        return noDuplicateServices.filter(
          (item) => item.id === this.issue.service_id
        );
      } else {
        return noDuplicateServices.filter(
          (item) => item.service_type === "reactive"
        );
      }
    },
  },
  watch: {
    accessToGeoLocation() {
      if (!this.accessToGeoLocation) {
        this.geoCheck = false;
      }
    },
    geoCheck() {
      if (this.geoCheck && !this.issue.id) {
        // ask about location on step 4
        const resolveFunction = (geoLocation) => {
          if (this.geoCheck) {
            this.issue.location = {
              longitude: geoLocation.longitude,
              latitude: geoLocation.latitude,
            };
          }
        };
        this.getGeoLocation(resolveFunction);
      }
    },
    services() {
      if (this.services.length === 1) {
        this.issue.service_id = this.services[0].id;
      }
    },
    "issue.service_id"() {
      if (this.dialog && !this.loading) {
        this.selected_next_state = null;
        this.fetchData();
      }
    },
    "issue.assets"() {
      !this.issue.id && this.issue.assets.length > 0
        ? this.getExistedIssues()
        : (this.existedIssues = []);
    },
    selected_next_state() {
      // check if display assets and prevent request if more states on same service
      if (this.selected_next_state) {
        if (
          this.isDisplayed("assets") &&
          this.serviceIdCheck !== this.issue.service_id
        ) {
          this.serviceIdCheck = this.issue.service_id;
          this.fetchAssetsFilters();
        }
        if (this.isDisplayed("files") && this.issue.id) {
          this.fetchDocuments();
        }
      }
    },
    dialog(newVar) {
      if (newVar) {
        this.selected_next_state = null;
        this.selectedFilter = null;
        this.existedIssues = [];
        this.new_service_id = null;
        this.geoCheck = false;
        this.value = {
          select_types: "Search",
        };
        this.resetForm();
        this.step = 1;
        this.stepper = 1;
      }
      if (!this.dialog) {
        this.componentRdy = false;
      }
    },
    selectedFilter(newVar) {
      if (newVar) {
        this.fetchAssetsFromFilter();
      }
    },
  },
  mounted() {
    this.$store.dispatch("loadable/fetchItems", "role_issue_state_transition");
    this.$store.dispatch("loadable/fetchItems", "issue_state");
  },
  methods: {
    openDialog(editObject, params, editMode) {
      this.issue = {
        ...{ persons: [], assets: [] },
        ...editObject,
      };
      this.files = [];
      this.filesFolderId =
        this.$store.state.basic_module.settings.doc_default_folders[
          "issue_documents"
        ];
      this.editMode = editMode;
      this.selected_next_state = null;
      this.fetchData();
      this.dialog = true;
      this.params = params;
    },
    fetchData() {
      if (this.issue.service_id) {
        this.loading = true;
        this.$http
          .get(`issues/change_state_load`, {
            params: {
              issue_id: this.issue.id,
              service_id: this.issue.service_id,
            },
          })
          .then(
            (response) => {
              this.next_states = response.body.next_states;

              if (this.next_states.length === 1) {
                this.selected_next_state = this.next_states[0];
              } else if (this.next_states.length < 1) {
                this.selected_next_state = null;
              }
            },
            (response) => {
              this.error_message("load", "issue", response);
            }
          )
          .then(() => {
            this.loading = false;
            this.componentRdy = true;
          });
      }
    },
    save() {
      const issueTemp = JSON.parse(JSON.stringify(this.issue));
      const formData = new FormData();
      formData.append("transition_id", this.selected_next_state.id);
      formData.append("issue_id", issueTemp.id);
      formData.append("files_folder_id", this.filesFolderId);
      for (const file of this.files) {
        formData.append("files[]", file);
      }

      if (this.new_service_id) {
        issueTemp.service_id = this.new_service_id;
      }
      formData.append("object", JSON.stringify(issueTemp));

      let redirectResult = null;
      if (!(this.noRedirect || issueTemp?.id)) {
        redirectResult = (response) => `/issues/${response.body.object.id}`;
      }

      const config = {
        method: "post",
        message: { success: true, action: "update", model: this.model },
        url: `issues/change_state`,
        requestParams: { headers: { "Content-Type": "multipart/form-data" } },
        reload: this.model,
        close_dialog: true,
        redirect: redirectResult,
        after: this.after,
      };
      this.actionsMixin_action(config, formData);
    },
    after(response) {
      this.resetForm();
      this.$emit("on-success", response.body.object);
    },
    fetchDocuments() {
      this.$http
        .get("entity_documents", {
          params: {
            filter: {
              entity_type: "Issue",
              entity_id: this.issue.id,
            },
          },
        })
        .then(
          (response) => {
            this.documents = response.body.objects;
          },
          (response) => {
            this.error_message("load", "document", response);
          }
        );
    },
    fetchAssetsFilters() {
      this.$http
        .get("data_sets", {
          params: { filter: { target_entity: "asset" } },
        })
        .then(
          (response) => {
            this.assetFilters = response.body.objects;
          },
          (response) => {
            this.error_message("load", "data_set", response);
          }
        );
    },
    fetchAssetsFromFilter() {
      this.allowNextStep = false;
      this.$http
        .get(`assets`, {
          params: {
            filter: this.selectedFilter.definition,
          },
        })
        .then(
          (response) => {
            this.issue.assets = response.body.objects;
            this.allowNextStep = true;
          },
          (response) => {
            this.error_message("load", "asset", response);
            this.allowNextStep = true;
          }
        );
    },
    getExistedIssues() {
      const filter = {
        all_states: false,
        asset_id: this.issue.assets.map((asset) => asset.id),
      };

      const options = {
        page: 1,
        itemsPerPage: 5,
        sortBy: ["created_at"],
        sortDesc: [true],
      };

      this.$http
        .get(`issues`, {
          params: {
            pagination: options,
            filter: filter,
          },
        })
        .then(
          (response) => {
            this.existedIssues = response.body.objects;
          },
          (response) => {
            this.error_message("load", "issue", response);
          }
        );
    },
    isDisplayed(input) {
      if (!this.selected_next_state) return false;

      return this.selected_next_state.definition.display.includes(input);
    },
    getRule(field) {
      if (this.selected_next_state.definition.validations[field]) {
        return this.customRules.field_required;
      }
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
  },
};
</script>

<style scoped></style>
