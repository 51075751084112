import _DatabaseModel, { i18n } from "./_DatabaseModel";

class PriceItem extends _DatabaseModel {
  static model = "price_item";
  static showSize = 8;
  static editDialogComponent = "price-items-edit-dialog";

  static tableHeaders = [
    { textKey: "form.label.name", value: "name" },
    {
      textKey: "price_item.label.price_type",
      value: "price_type",
      translationPrefix: "price_item.price_types",
    },
  ];

  static show = {
    buttons: ["edit"],
    submenuActions: ["duplicate", "activity", "destroy"],
    reloadingModels: ["external_guid", "price_items_price_list"],
  };

  static filter = {
    parts: {
      fields: ["name", "price_type", "gtin", "code_1", "code_2"],
      extended_fields: ["description", "connector_id", "time_filter"],
      time_filter: ["created_at", "updated_at"],
    },
  };

  static operations = ["export"];

  static price_types = ["material", "transport", "work"];

  static cardFields(object) {
    return {
      title: object.name,
      subtitle: i18n.t(`price_item.price_types.${object.price_type}`),
    };
  }

  static basicInfo(object) {
    return [
      {
        labelKey: "price_item.label.price_type",
        value: i18n.t(`${"price_item.price_types"}.${object.price_type}`),
        type: "plain",
      },
      {
        labelKey: "price_item.label.unit",
        value: i18n.t(`${"price_item.units"}.${object.unit}`),
        type: "plain",
      },
      {
        labelKey: "price_item.label.batches",
        value: i18n.t(`generic.${object.batches}`),
        type: "plain",
        condition: object.price_type === "material",
      },
      {
        labelKey: "price_item.label.gtin",
        value: object.gtin,
        type: "plain",
      },
      {
        labelKey: "price_item.label.code_1",
        value: object.code_1,
        type: "plain",
      },
      {
        labelKey: "price_item.label.code_2",
        value: object.code_2,
        type: "plain",
      },
      {
        labelKey: "form.label.created_at",
        value: object.created_at,
        type: "date_time",
      },
    ];
  }

  static tabs = [
    this.detailTab,
    {
      titleKey: "warehouse.warehouses",
      permission: "price_items_tab_warehouses",
      component: "WarehousesTab",
      input_data() {},
      parameters_f() {},
      disabled: () => false,
    },
    {
      titleKey: "external_guid.external_guids",
      permission: "price_items_tab_external_guids",
      component: "ExternalGuidsTab",
      input_data: (context) => context.object.external_guids,
      parameters_f() {},
      disabled: () => false,
    },
  ];
}

export default PriceItem;
