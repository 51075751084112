<template>
  <v-form
    v-if="
      template_assignments_computed.length > 0 &&
      typeof value !== 'undefined' &&
      value !== null
    "
  >
    <v-expansion-panels v-model="panel" multiple accordion>
      <v-expansion-panel
        v-for="(item, index) in template_assignments_computed"
        :key="`form_panel_${index}`"
      >
        <v-expansion-panel-header>
          <strong>{{ getTranslation(item.name) }}</strong>
        </v-expansion-panel-header>

        <v-expansion-panel-content eager>
          <v-card-text>
            <v-form :ref="`form_${index}`">
              <template-visualization-inner
                v-if="value"
                v-model="value"
                :field-assignments="item.field_assignments"
                :template-assignment-id="item.id"
                @select-images="setImages"
              ></template-visualization-inner>
            </v-form>
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-form>
</template>

<script>
import BasicMixin from "../../../../_generic/mixins/BasicMixin";
import TemplateVisualizationInner from "./TemplateVisualizationInner";

export default {
  name: "TemplateVisualization",
  components: { TemplateVisualizationInner },
  mixins: [BasicMixin],
  props: {
    templateAssignments: Array,
    value: Object,
    expandFirst: Boolean,
  },
  data: () => ({
    panel: [],
  }),
  computed: {
    template_assignments_computed() {
      if (!this.templateAssignments) return [];

      return this.templateAssignments;
    },
  },
  watch: {
    templateAssignments() {
      this.setup_value();
    },
    value() {
      if (this.value) {
        this.$emit("input", this.value);
      } else {
        this.setup_value();
      }
    },
  },
  created() {
    this.setup_value();
  },
  methods: {
    setImages(images) {
      this.$emit("select-images", images);
    },
    setup_value() {
      const newValue = {};

      for (const item of this.templateAssignments) {
        for (const field_a of item.field_assignments) {
          newValue[field_a.field.key] = "";
        }
      }

      if (this.expandFirst && this.template_assignments_computed.length > 0) {
        this.panel = [0];
      } else {
        this.panel = [];
      }
      this.$emit("input", { ...newValue, ...this.value });
    },
    validate() {
      let valid = true;
      for (const i in this.template_assignments_computed) {
        if (!this.$refs[`form_${i}`][0].validate()) {
          // if part form not valid
          if (!this.panel.includes(parseInt(i))) {
            this.panel.push(parseInt(i));
          }
          valid = false;
        }
      }

      return valid;
    },
    resetValidation() {
      for (const i in this.template_assignments_computed) {
        this.$refs[`form_${i}`][0].resetValidation();
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
