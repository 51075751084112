<template>
  <v-card class="my-2" rounded="lg" :class="cardClass">
    <v-card-text @click="$emit('selected-item', item)">
      <div class="text-subtitle-1 d-flex">
        <link-checked
          :text="item.name"
          disabled
          bottom-margin
          truncate
        ></link-checked>

        <span class="ml-auto">#{{ item.id }}</span>
      </div>

      <div>
        <issue-state-tag :state="item.issue_state" bottom-margin />
        <issue-priority-tag :priority="item.issue_priority" bottom-margin />
        <base-object-tags :object="item" bottom-margin></base-object-tags>
      </div>

      <div class="d-flex flex-wrap">
        <div v-if="item.resolve_at" class="mr-2 mb-2">
          <v-icon small class="mr-1">{{ mdiCalendarMonth }}</v-icon>
          {{ item.resolve_at | moment(date_format()) }}
        </div>

        <link-checked
          v-if="item.service_id"
          :id="item.service.id"
          to="services"
          :text="item.service.name"
          :discarded-at="item.service.discarded_at"
          :icon="mdiWrench"
          bottom-margin
          truncate
        ></link-checked>

        <link-checked
          v-for="(asset, ind) of item.assets?.slice(0, 2)"
          :id="asset.id"
          :key="asset.id"
          to="assets"
          :text="asset.name"
          :discarded-at="asset.discarded_at"
          :icon="ind === 0 ? mdiOfficeBuilding : ''"
          :append-text="ind === 0 && item.assets.length > 1 ? ',' : ''"
          bottom-margin
          truncate
        />
        <div v-if="item.assets?.length > 2" class="mr-2 mb-2">
          +{{ item.assets.length - 2 }}
        </div>

        <link-checked
          v-if="item.persons?.length >= 1"
          :id="item.persons[0].id"
          to="persons"
          :text="item.persons[0].name"
          :discarded-at="item.persons[0].discarded_at"
          :icon="mdiAccount"
          bottom-margin
          truncate
        />
        <div
          v-if="new Set(item.persons.map((p) => p.id)).size > 1"
          class="mb-2"
        >
          +{{ new Set(item.persons.map((p) => p.id)).size - 1 }}
        </div>
      </div>

      <div class="d-flex flex-wrap justify-end align-baseline">
        <link-checked
          v-if="item.creator"
          :id="item.creator_id"
          :to="toModel(item.creator_type, true)"
          :text="item.creator.name || item.creator.email"
          :discarded-at="item.creator.discarded_at"
          :src="item.creator"
          :icon-type="item.creator_type || 'System'"
          truncate
        ></link-checked>

        <base-tooltip-date-data
          :datetime="item.created_at"
        ></base-tooltip-date-data>
      </div>
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script>
import BasicMixin from "../../../_generic/mixins/BasicMixin";
import IssueStateTag from "./components/IssueStateTag";
import IssuePriorityTag from "./components/IssuePriorityTag";
import LinkChecked from "../components/LinkChecked";
import BaseTooltipDateData from "../../../_generic/pages/components/base/BaseTooltipDateData";
import PrintingDate from "../../../_generic/mixins/PrintingDate";
import BaseObjectTags from "../../../_generic/pages/components/base/BaseObjectTags";
import {
  mdiOfficeBuilding,
  mdiClipboardOutline,
  mdiAccount,
  mdiWrench,
  mdiCalendarMonth,
  mdiStickerCheckOutline,
} from "@mdi/js";

export default {
  name: "IssueCard",
  components: {
    BaseObjectTags,
    BaseTooltipDateData,
    LinkChecked,
    IssuePriorityTag,
    IssueStateTag,
  },
  mixins: [BasicMixin, PrintingDate],
  props: {
    item: Object,
    selected: Boolean,
    draggable: Boolean,
  },
  data: () => ({
    mdiClipboardOutline,
    mdiOfficeBuilding,
    mdiAccount,
    mdiWrench,
    mdiCalendarMonth,
    mdiStickerCheckOutline,
  }),
  computed: {
    cardClass() {
      const classes = [this.draggable ? "grab" : "pointer"];
      if (this.selected) {
        classes.push(this.$vuetify.theme.dark ? "primary" : "selectedBorder");
      }

      return classes;
    },
  },
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
.grab {
  cursor: grab;
}
</style>
