import _DatabaseModel from "./_DatabaseModel";

class ExternalEmail extends _DatabaseModel {
  static model = "external_email";
  static createNewObject = false;

  static tableHeaders = [
    { textKey: "form.label.email", value: "email" },
    { textKey: "form.label.created_at", value: "created_at" },
  ];

  static show = { submenuActions: ["activity"], reloadingModels: [] };

  static filter = {
    parts: {
      fields: ["email", "time_filter"],
      extended_fields: [],
      time_filter: ["created_at", "updated_at"],
    },
  };

  static cardFields(object) {
    return {
      title: object.email,
    };
  }

  static basicInfo(object) {
    return [
      {
        labelKey: "form.label.created_at",
        value: object.created_at,
        type: "date_time",
      },
    ];
  }

  static tabs = [
    {
      titleKey: "issue.issues",
      permission: "external_emails_tab_issues",
      component: "IssuesTab",
      input_data() {},
      parameters_f() {},
      disabled: () => false,
    },
  ];
}

export default ExternalEmail;
