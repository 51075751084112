import _DatabaseModel from "./_DatabaseModel";

class StandardSoa extends _DatabaseModel {
  static model = "standard_soa";
  static showSize = 8;
  static editDialogComponent = "standard-soas-edit-dialog";

  static tableHeaders = [
    { textKey: "form.label.standard_id", value: "standard.name" },
    { textKey: "form.label.organization_id", value: "person.name" },
  ];

  static show = { submenuActions: ["activity", "destroy"] };

  static filter = {
    parts: {
      fields: ["person_id", "standard_id"],
      extended_fields: [],
      time_filter: ["created_at", "updated_at"],
    },
  };

  static cardFields(object) {
    return {
      title: object.name,
      subtitle: object.person.name,
    };
  }

  static basicInfo(object) {
    return [
      {
        labelKey: "form.label.standard_id",
        value: object.standard,
        type: "link",
        to: "standards",
      },
      {
        labelKey: "form.label.organization_id",
        value: object.person,
        type: "link",
        to: "persons",
      },
      {
        labelKey: "form.label.updated_at",
        value: object.updated_at,
        type: "date_time",
      },
      {
        labelKey: "form.label.created_at",
        value: object.created_at,
        type: "date_time",
      },
    ];
  }

  static tabs = [
    this.detailTab,
    {
      titleKey: "audit.label.requirements",
      permission: "standard_soa_tab_valuation",
      component: "AuditsValuationTab",
      input_data: (context) => context.object.standard?.sections,
      parameters_f() {},
      disabled: () => false,
    },
  ];
}

export default StandardSoa;
