import _DatabaseModel, { i18n } from "./_DatabaseModel";

class Kpi extends _DatabaseModel {
  static model = "kpi";
  static editDialogComponent = "kpi-create-wizard";

  static createNewObject = {
    object: {
      definition: { calculation: {}, visualization: {} },
    },
  };

  static tableHeaders = [
    { textKey: "form.label.name", value: "name" },
    { text: "", value: "tags", sortable: false },
    {
      textKey: "kpi.label.calculation",
      value: "calculation",
      translationPrefix: "kpi.calculations",
    },
    {
      textKey: "kpi.label.visualization",
      value: "visualization",
      translationPrefix: "kpi.visualizations",
    },
  ];

  static show = {
    buttons: ["edit"],
    submenuActions: [
      "duplicate",
      "sync",
      "exportXLSX",
      "permittedPersons",
      "activity",
      "destroy",
    ],
    reloadingModels: [],
  };

  static filter = {
    parts: {
      fields: ["name", "calculation", "visualization"],
      extended_fields: ["description", "time_filter"],
      time_filter: ["created_at", "updated_at"],
    },
  };

  static operations = ["export"];

  static refresh_rates = [0, 60, 360, 1440];

  static calculations = [
    "ratio",
    "issue_count",
    "time_series",
    "state_change",
    "state_sum",
    "detail_sum",
    "map",
    "map_colored",
    "asset_risk_count",
    "consumption",
    "transaction_item_sum",
  ];

  static visualizations = [
    "map",
    "pie_chart",
    "donut_chart",
    "column_chart",
    "bar_chart",
    "line_chart",
    "area_chart",
    "time_line",
    "stacked_column_chart",
    "stacked_bar_chart",
    "table",
  ];

  static cardFields(object) {
    return {
      title: object.name,
      subtitle: i18n.t(`kpi.visualizations.${object.visualization}`),
      tags: true,
    };
  }

  static basicInfo(object) {
    return [
      {
        labelKey: "kpi.label.refresh_rate",
        value: i18n.t(`kpi.refresh_rates.${object.refresh_rate}`),
        type: "plain",
      },
      {
        labelKey: "kpi.label.calculation",
        value: i18n.t(`kpi.calculations.${object.calculation}`),
        type: "plain",
      },
      {
        labelKey: "kpi.label.visualization",
        value: i18n.t(`kpi.visualizations.${object.visualization}`),
        type: "plain",
      },
      {
        labelKey: "kpi.label.computed_at",
        value: object.computed_at,
        type: "date_time",
      },
      {
        labelKey: "form.label.created_at",
        value: object.created_at,
        type: "date_time",
      },
    ];
  }

  static tabs = [
    this.detailTab,
    {
      titleKey: "kpi.label.preview",
      permission: "kpis_tab_preview",
      component: "PreviewKpiTab",
      input_data: (context) => context.object,
      parameters_f() {},
      disabled: (context) => context.dataShow_disableUpdate,
    },
    {
      titleKey: "kpi.label.definition",
      permission: "kpis_tab_definition",
      component: "JsonDefinitionTab",
      input_data: (context) => context.object,
      parameters_f() {},
      disabled: (context) => context.dataShow_disableUpdate,
    },
    {
      titleKey: "kpi.label.data",
      permission: "kpis_tab_data",
      component: "KpiDataTab",
      input_data: (context) => context.object.data,
      parameters_f() {},
      disabled: () => false,
    },
  ];

  static exportXLSX(kpi) {
    import("xlsx").then((xlsx) => {
      const wb = xlsx.utils.book_new();
      const sheet = xlsx.utils.aoa_to_sheet(kpi.data);
      let sheetName = kpi.name;
      if (sheetName.length > 31) {
        sheetName = sheetName.slice(0, 28) + "...";
      }
      xlsx.utils.book_append_sheet(wb, sheet, sheetName);
      xlsx.writeFile(wb, "kpi_export.xlsx");
    });
  }
}

export default Kpi;
