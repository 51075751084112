<template>
  <base-edit-dialog
    :dialog.sync="dialog"
    :label="$t('tooltips.tabs_icons.checklist_preview')"
    :positive-translation="null"
  >
    <div v-if="edit_object.assets_service_id">
      <templates-detail-tab
        v-if="edit_object.solve_status !== 'not_run'"
        :input_data="{
          templatable_id: edit_object.assets_service_id,
          properties: edit_object.properties,
          template_assignments: params.template_assignments,
        }"
      ></templates-detail-tab>
    </div>

    <div class="text-center pt-2">
      <base-text-button
        :tooltip-translation="$t('tooltips.show_buttons.add_issue')"
        :tooltip-disabled-translation="
          $t('tooltips.show_buttons.disabled_buttons.generic')
        "
        :icon="mdiPlusCircle"
        :button-text="$t('tooltips.show_buttons.add_issue')"
        color="success"
        @click="create_issue"
      ></base-text-button>
      <issue-change-state
        ref="issue_edit_dialog"
        no-redirect
      ></issue-change-state>
    </div>
  </base-edit-dialog>
</template>

<script>
import BaseEditDialog from "../../../../_generic/pages/components/base/BaseEditDialog";
import DataEdit from "../../../../_generic/mixins/DataEdit";
import TemplatesDetailTab from "../../tabs/TemplatesDetailTab";
import BaseTextButton from "../../../../_generic/pages/components/base/BaseTextButton";
import IssueChangeState from "./IssueChangeState";
import ChecklistMixin from "../../../mixins/ChecklistMixin";
import { mdiPlusCircle } from "@mdi/js";

export default {
  name: "ChecklistPreviewDialog",
  components: {
    BaseTextButton,
    BaseEditDialog,
    IssueChangeState,
    TemplatesDetailTab,
  },
  mixins: [DataEdit, ChecklistMixin],
  data: () => ({
    model: "assets_issue",
    mdiPlusCircle,
  }),
  methods: {
    create_issue() {
      const newIssue = {
        assets: [
          {
            id: this.edit_object.asset.id,
            name: this.edit_object.asset.name,
          },
        ],
        link_to: { id: this.edit_object.issue_id },
      };
      this.$refs.issue_edit_dialog.openDialog(newIssue, {}, false);
    },
  },
};
</script>

<style scoped></style>
