const JsonWizardEditMixin = {
  methods: {
    jsonWizardEditMixin_inputJson(event) {
      try {
        this.edit_object.definition = JSON.parse(event);
      } catch (e) {
        return false;
      }
    },
    jsonWizardEditMixin_inputWizard() {
      this.definition_str = JSON.stringify(
        this.edit_object.definition || {},
        null,
        4
      );
    },
  },
};

export default JsonWizardEditMixin;
